import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ResponseData } from '../interfaces/response.interface';
import { BaseApi } from './base.api';

@Injectable({
  providedIn: 'root',
})
export class CollectorApi extends BaseApi {
  /**
   * 傳送inviteCode相關binding資訊
   *
   * @param params
   * @param params.inviteCode
   * @param params.ip
   * @param params.fingerprint
   * @returns boolean
   */
  sendInviteCodeInfo(params: { inviteCode: string; ip: string; fingerprint: string }): Observable<boolean> {
    const url = `${environment.apiUrl}/v1/member/collector/collect`;
    return this.post<ResponseData<boolean>>(url, params).pipe(map(x => x?.data));
  }
}

import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  ActiveBonus,
  ActiveBonusBalance,
  CurrencyBalance,
  EligibleBonus,
} from '../interfaces/new-bonus-wallet.interface';
import { ResponseData } from '../interfaces/response.interface';
import { BaseApi } from './base.api';

@Injectable({
  providedIn: 'root',
})
export class NewBonusWalletApi extends BaseApi {
  /**
   * 獎金中心頁面，取得啟用中的獎金錢包與凍結餘額(USDT)
   *
   * @returns
   */
  getActiveBonusBalance(): Observable<ActiveBonusBalance> {
    const url = `${environment.apiUrl}/v1/asset/newbonuswallet/getactivebonusbalance`;
    return this.get<ResponseData<ActiveBonusBalance>>(url).pipe(map(res => res.data));
  }

  /**
   * 獎金中心頁面，取得啟用中的獎金錢包餘額
   *
   * @returns
   */
  getActiveBonusBalanceByCurrency(): Observable<CurrencyBalance[]> {
    const url = `${environment.apiUrl}/v1/asset/newbonuswallet/getactivebonusbalancebycurrency`;
    return this.get<ResponseData<CurrencyBalance[]>>(url).pipe(map(res => res.data || []));
  }

  /**
   * 獎金中心頁面，取得獎金錢包凍結金額
   *
   * @returns
   */
  getBonusFreezeAmountByCurrency(): Observable<CurrencyBalance[]> {
    const url = `${environment.apiUrl}/v1/asset/newbonuswallet/getbonusfreezeamountbycurrency`;
    return this.get<ResponseData<CurrencyBalance[]>>(url).pipe(map(res => res.data || []));
  }

  /**
   * 奖金中心页面，取得用户啟用中的奖金钱包
   *
   * @returns
   */
  getActiveBonus(): Observable<ActiveBonus[]> {
    const url = `${environment.apiUrl}/v1/asset/newbonuswallet/getactivebonus`;
    return this.get<ResponseData<ActiveBonus[]>>(url).pipe(
      map(res => res.data.map(item => ({ ...item, remainingTime: '' })) || []),
    );
  }

  /**
   * 奖金中心页面，取得用户符合資格的奖金
   *
   * @returns
   */
  getEligibleBonus(): Observable<EligibleBonus[]> {
    const url = `${environment.apiUrl}/v1/asset/newbonuswallet/geteligiblebonus`;
    return this.get<ResponseData<EligibleBonus[]>>(url).pipe(map(res => res.data || []));
  }

  /**
   * 奖金中心页面，用户自行取消獎金錢包
   *
   * @param id
   * @returns
   */
  cancelBonus(id: number): Observable<boolean> {
    const url = `${environment.apiUrl}/v1/asset/newbonuswallet/cancelbonus`;
    return this.post<ResponseData<boolean>>(url, { id }).pipe(map(res => res.data || false));
  }
}

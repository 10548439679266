import { Injectable } from '@angular/core';
import { FingerprintjsProAngularService } from '@fingerprintjs/fingerprintjs-pro-angular';
import { CollectorApi } from 'src/app/shared/apis/collector.api';
import { LocalStorageService } from './localstorage.service';

@Injectable({
  providedIn: 'root',
})
export class CollectorService {
  constructor(
    private localStorageService: LocalStorageService,
    private fpService: FingerprintjsProAngularService,
    private collectorApi: CollectorApi
  ) {}

  /**
   * 自動傳送APP下載第三方推薦碼
   */
  async sendAppInviteCode() {
    const inviteCode = this.localStorageService.inviteCodeSignal() || '';
    const ip = this.localStorageService.clientIp || '';
    const fingerprint = this.localStorageService.visitorIdPro || this.localStorageService.visitorId || '';

    // await this.fpService.getVisitorData({ extendedResult: true }).then(x => console.log('fingerPrint ', x));
    const extendedResult = await this.fpService.getVisitorData({ extendedResult: true });
    console.log('fingerPrint', extendedResult);

    this.collectorApi.sendInviteCodeInfo({ inviteCode, ip, fingerprint }).subscribe();
  }
}
